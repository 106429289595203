jQuery(document).ready(function ($) {      
    $('.include-slider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows:false
    });

});